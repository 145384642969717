import { RoutingChannel } from '@api-hooks/transaction';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import humps from 'humps';
import get from 'lodash/get';

import { RecipientFormDetails, RecipientType } from './types';

export function getIsRoutingChannelMaintenance(
  routingChannels: RoutingChannel[] | undefined,
  selectedRoutingChannel: RoutingChannelType,
) {
  return (
    routingChannels?.find(
      (routingChannel) => routingChannel.type === selectedRoutingChannel,
    )?.isMaintenance || false
  );
}

export function hasMaintenance(routingChannel: RoutingChannel[] | undefined) {
  return (
    routingChannel?.find((channel) => channel.isMaintenance)?.isMaintenance ||
    false
  );
}

interface Condition {
  name: string;
  recipientSegment: RecipientType;
  senderSegment: RecipientType;
  value: number[];
}

/**
 * Determines whether a field should be shown based on provided conditions.
 */
export function getCanShowField({
  recipientSegment,
  senderSegment,
  conditions,
  values,
}: {
  recipientSegment: RecipientType;
  senderSegment: RecipientType;
  conditions: Condition[];
  values: RecipientFormDetails;
}): boolean {
  // Check the sender & recipient segment -> Check is value included in allowed shown value list
  return conditions.some((condition) => {
    const fieldName = humps.camelize(condition.name);
    const isSenderMatch = condition.senderSegment === senderSegment;
    const isRecipientMatch = condition.recipientSegment === recipientSegment;
    const fieldValue = get(values, fieldName);

    const isValueMatch = condition.value
      .map((val) => String(val))
      .includes(String(fieldValue));

    return isSenderMatch && isRecipientMatch && isValueMatch;
  });
}
