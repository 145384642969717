import { IQuotationMultipleRecipient } from '@api-hooks/domestic';
import { queryClient, requestFn } from '@common/client';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  RemitQuotationModel,
  RemitQuotationParam,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  encryptString,
  getCurrentDate,
} from '@topremit/shared-web/common/helper';
import { useMutation, UseMutationOptions } from 'react-query';

import {
  Airtime,
  AirtimeParam,
  CheckVoucherParam,
  CreateBulkQuotationParams,
  CreateTransactionParam,
  DomesticCreateBatchTransactionParam,
  DontShowLimitReminderParam,
  MakePayment,
  MakePaymentParam,
  MassTransactionsActionResponse,
  PaymentConfirmation,
  Attachment,
  ShowEta,
  TransactionCancel,
  TransactionDetail,
  UploadPaymentProofModel,
  Voucher,
  MakePaymentBusinessParam,
  TransactionTrackerModel,
  TransactionTrackerParam,
} from './transaction.model';

export function usePostRemitQuotation(
  options?: UseMutationOptions<ApiResult<RemitQuotationModel>, ApiError>,
) {
  return useMutation<ApiResult<RemitQuotationModel>, ApiError, any>(
    async (param: RemitQuotationParam) =>
      await requestFn(
        {
          path: 'quotations',
          method: 'post',
        },
        {
          json: param,
          headers: {
            'X-REQ-KEY': String(
              encryptString(
                getCurrentDate(),
                String(process.env.NEXT_PUBLIC_SECRET_KEY),
              ),
            ),
          },
        },
      ),
    options,
  );
}

export function useSetAirtime(
  options?: UseMutationOptions<ApiResult<Airtime>, ApiError, AirtimeParam>,
) {
  return useMutation<ApiResult<Airtime>, ApiError, AirtimeParam>(
    async (param: AirtimeParam) =>
      await requestFn(
        {
          path: 'airtime/create',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function usePostDontShowLimitReminder(
  options?: UseMutationOptions<
    ApiResult<number>,
    ApiError,
    DontShowLimitReminderParam
  >,
) {
  const response = useMutation<
    ApiResult<number>,
    ApiError,
    DontShowLimitReminderParam
  >(
    async (params: DontShowLimitReminderParam) =>
      await requestFn(
        {
          path: `dont-show`,
          method: 'post',
        },
        {
          json: params,
        },
      ),
    options,
  );
  return response;
}

export function useCancelTransaction(
  refId: string,
  options?: UseMutationOptions<ApiResult<TransactionCancel>, ApiError>,
) {
  return useMutation<ApiResult<TransactionCancel>, ApiError>(
    async () =>
      await requestFn({
        path: `v2/transactions/${refId}/cancel`,
        method: 'post',
      }),
    options,
  );
}

export function useCheckVoucher(
  options?: UseMutationOptions<ApiResult<Voucher>, ApiError, CheckVoucherParam>,
) {
  return useMutation<ApiResult<Voucher>, ApiError, CheckVoucherParam>(
    ({ type, code, refId, hasDiscountFee, isBatch }: CheckVoucherParam) => {
      return requestFn(
        {
          path: `v2/quotations/${refId}/vouchers/${code}`,
          method: 'post',
        },
        {
          json: {
            type,
            hasDiscountFee,
            isBatch,
          },
        },
      );
    },
    {
      ...options,
      retry: false,
    },
  );
}

export function useCreateTransaction(
  options?: UseMutationOptions<
    ApiResult<TransactionDetail>,
    ApiError,
    CreateTransactionParam
  >,
) {
  return useMutation<
    ApiResult<TransactionDetail>,
    ApiError,
    CreateTransactionParam
  >(
    (param: CreateTransactionParam) => {
      return requestFn(
        {
          path: `v2/transactions`,
          method: 'post',
        },
        { json: param },
      );
    },
    {
      ...options,
      retry: false,
    },
  );
}

export function useCreateBatchTransaction<
  T = DomesticCreateBatchTransactionParam,
  U = TransactionDetail[],
>(options?: UseMutationOptions<ApiResult<U>, ApiError, T>) {
  return useMutation<ApiResult<U>, ApiError, T>(
    (param: T) => {
      return requestFn(
        {
          path: `v2/transaction-batches`,
          method: 'post',
        },
        { json: param },
      );
    },
    {
      ...options,
      retry: false,
    },
  );
}

export function useMakePaymentPersonal(
  options?: UseMutationOptions<
    ApiResult<MakePayment>,
    ApiError,
    MakePaymentParam
  >,
) {
  return useMutation<ApiResult<MakePayment>, ApiError, MakePaymentParam>(
    (param: MakePaymentParam) => {
      return requestFn(
        {
          path: `payments`,
          method: 'post',
        },
        { json: param },
      );
    },
    {
      ...options,
      retry: false,
    },
  );
}

export function useMakePaymentBusiness(
  refId: string,
  options?: UseMutationOptions<ApiResult, ApiError, MakePaymentBusinessParam>,
) {
  return useMutation<ApiResult, ApiError, MakePaymentBusinessParam>(
    (param: MakePaymentBusinessParam) => {
      return requestFn(
        {
          path: `v2/transactions/${refId}/approve-payment`,
          method: 'post',
        },
        { json: param },
      );
    },
    options,
  );
}

export function useRefreshTimer(
  refId: string,
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError>(() => {
    return requestFn({
      path: `v2/transactions/${refId}/refresh`,
      method: 'post',
    });
  }, options);
}

export function usePaymentConfirmations(
  refId: string,
  options?: UseMutationOptions<ApiResult<PaymentConfirmation>, ApiError>,
) {
  return useMutation<ApiResult<PaymentConfirmation>, ApiError>(
    async () =>
      await requestFn({
        path: `transactions/${refId}/payment-confirmations`,
        method: 'post',
      }),
    options,
  );
}

export function useUploadPaymentProof(
  refId: string,
  options?: UseMutationOptions<
    ApiResult<Attachment>,
    ApiError,
    UploadPaymentProofModel
  >,
) {
  return useMutation<ApiResult<Attachment>, ApiError, UploadPaymentProofModel>(
    async (param: UploadPaymentProofModel) =>
      await requestFn(
        {
          path: `transactions/${refId}/payment-proofs`,
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useUpdateShowEtaCoachmark(
  options?: UseMutationOptions<ApiResult<ShowEta>, ApiError>,
) {
  return useMutation<ApiResult<ShowEta>, ApiError>(
    () => {
      return requestFn({
        path: 'me/show-eta-coachmark',
        method: 'put',
      });
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useCreateBulkQuotation(
  options?: UseMutationOptions<
    ApiResult<IQuotationMultipleRecipient>,
    ApiError,
    CreateBulkQuotationParams
  >,
) {
  return useMutation<
    ApiResult<IQuotationMultipleRecipient>,
    ApiError,
    CreateBulkQuotationParams
  >(
    async (param) =>
      await requestFn(
        { path: 'quotation-batch/recipients', method: 'post' },
        { json: param },
      ),
    {
      retry: false,
      ...options,
    },
  );
}

/**
 * Approves or Reject a transaction.
 * available for **business** account types and requires the `transaction.approve|transaction.reject` permission.
 *
 */
export function useApproveRejectTransaction(
  id: string,
  onError?: (e: ApiError) => void,
) {
  return useMutation(
    (type: 'approve' | 'reject') =>
      requestFn({ path: `v2/transactions/${id}/${type}`, method: 'post' }),
    {
      retry: false,
      onError,
    },
  );
}

/**
 * API to approve pending business transactions
 * if one of the transaction failed to approve, response status will also 200
 *
 */
export function useApproveTransactions() {
  return useMutation<
    ApiResult<MassTransactionsActionResponse>,
    ApiError,
    { refIds: string[] }
  >(
    async (param) =>
      await requestFn(
        { path: 'v2/transactions/mass-approve', method: 'post' },
        { json: param },
      ),
  );
}

/**
 * API to approve pending business transactions
 * if one of the transaction failed to approve, response status will also 200
 *
 */
export function useRejectTransactions() {
  return useMutation<
    ApiResult<MassTransactionsActionResponse>,
    ApiError,
    { refIds: string[] }
  >(
    async (param) =>
      await requestFn(
        { path: 'v2/transactions/mass-reject', method: 'post' },
        { json: param },
      ),
  );
}

export function useTrackTransaction(
  options?: UseMutationOptions<
    ApiResult<TransactionTrackerModel>,
    ApiError,
    TransactionTrackerParam
  >,
) {
  return useMutation<
    ApiResult<TransactionTrackerModel>,
    ApiError,
    TransactionTrackerParam
  >(
    ({ refId, token, name }) =>
      requestFn(
        {
          path: `transactions/${refId}/track`,
          method: 'get',
        },
        {
          searchParams: {
            'g-recaptcha-response': token,
            ...(name && { name }),
          },
        },
      ),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        if (data?.data) {
          queryClient.setQueryData(
            ['transaction-tracker', variables.refId],
            data.data,
          );
        }

        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}
