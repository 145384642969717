import { AccountType } from '@api-hooks/common';
import { css } from '@emotion/css';
import { useCurrentAccountStore } from '@stores';
import {
  PayersModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  Flex,
  ListMenu,
  Notes,
  parseHtml,
  Status,
  Text,
} from '@topremit/shared-web/components/elements';
import { DelayFill, Thunder } from '@topremit/shared-web/components/shapes';
import { useDebounce, useTranslation } from '@topremit/shared-web/hooks';
import { Searchbar } from '@topremit-ui/searchbar';
import getConfig from 'next/config';
import Image from 'next/image';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

const { publicRuntimeConfig } = getConfig();
const { staticFolder } = publicRuntimeConfig || {};

const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';

type SelectedPayerIdState = [
  number | null,
  Dispatch<SetStateAction<number | null>>,
];

type ShowMaintenanceNotesState = [boolean, Dispatch<SetStateAction<boolean>>];

export interface ISelectDestinationBank {
  payers?: PayersModel[];
  isRequestable?: boolean;
  searchPlaceholder?: string;
  emptyStateImage: ReactNode;
  disabledUnsupportedSpeed?: boolean;
  routingChannel?: RoutingChannelType;
  selectedPayerIdState: SelectedPayerIdState;
  showMaintenancesNotesState: ShowMaintenanceNotesState;
}

interface PayersModelDisabled extends PayersModel {
  disabled?: boolean;
}

export default function SelectDestinationBankModalBody({
  payers,
  isRequestable,
  routingChannel,
  emptyStateImage,
  searchPlaceholder,
  selectedPayerIdState,
  disabledUnsupportedSpeed,
  showMaintenancesNotesState,
}: ISelectDestinationBank) {
  const { t } = useTranslation();

  const strPayers = JSON.stringify(payers);
  const strRoutingChannel = JSON.stringify(routingChannel);

  const currentAccount = useCurrentAccountStore(
    (store) => store.currentAccount,
  );
  const href =
    currentAccount?.type === AccountType.BUSINESS
      ? 'https://docs.google.com/forms/d/1vqs1Fa7mnDW7cSxiCxvhVQvbRoqAM2v2fOp5q-0b8T4/viewform?edit_requested=true'
      : 'https://docs.google.com/forms/d/1bZyP5qHzLsu7yFh9pI-UBZB7_X8RRBfWJ4fPwzWmOLs/viewform?edit_requested=true';

  const _payers = useMemo(() => {
    if (payers && routingChannel) {
      return sortPayersByRoutingChannel(payers, routingChannel);
    }

    if (payers) {
      return payers;
    }

    return [];
  }, [strPayers, strRoutingChannel]);

  const [search, setSearch] = useState('');
  const [selectedPayerId, setSelectedPayerId] = selectedPayerIdState;
  const [showMaintenanceNotes, setShowMaintenanceNotes] =
    showMaintenancesNotesState;
  const [filteredPayers, setFilteredPayers] = useState<
    PayersModelDisabled[] | undefined
  >(_payers);

  function sortPayersByRoutingChannel(
    payers: PayersModel[],
    routingChannel: RoutingChannelType,
  ) {
    const regularPayers: PayersModel[] = [];
    const instantPayers: PayersModel[] = [];
    const instantRegularPayers: PayersModel[] = [];

    payers.forEach((payer) => {
      const hasInstant = payer.routingChannels.some(
        ({ type }) => type === RoutingChannelType.INSTANT,
      );
      const hasRegular = payer.routingChannels.some(
        ({ type }) => type === RoutingChannelType.REGULAR,
      );

      if (hasInstant && !hasRegular) {
        instantPayers.push(payer);
      } else if (hasInstant && hasRegular) {
        instantRegularPayers.push(payer);
      } else if (!hasInstant && hasRegular) {
        regularPayers.push(payer);
      }
    });

    const sortedInstantPayers = [
      ...instantPayers,
      ...instantRegularPayers,
    ].sort((a, b) => a.name.localeCompare(b.name));

    const sortedRegularPayers = [
      ...regularPayers,
      ...instantRegularPayers,
    ].sort((a, b) => a.name.localeCompare(b.name));

    const _payers =
      routingChannel === RoutingChannelType.INSTANT
        ? [...sortedInstantPayers, ...regularPayers]
        : [...sortedRegularPayers, ...instantPayers];

    if (disabledUnsupportedSpeed && routingChannel) {
      return _payers.map((payer) => {
        return {
          ...payer,
          disabled: !payer.routingChannels.some(
            ({ type }) => type === routingChannel,
          ),
        };
      });
    }

    return _payers;
  }

  function onSelectBank(id, routingChannels) {
    const _routingChannels = JSON.stringify(routingChannels);
    localStorage.setItem(PAYER_ROUTING_CHANNEL, _routingChannels);
    setSelectedPayerId(id);
    if (routingChannels.some(({ isMaintenance }) => isMaintenance)) {
      setShowMaintenanceNotes(true);
    } else {
      setShowMaintenanceNotes(false);
    }
  }

  function renderMaintenanceNotes() {
    if (!filteredPayers) return null;

    if (showMaintenanceNotes) {
      return (
        <Notes
          showIcon
          status="warning"
          icon={<DelayFill width={24} height={24} />}
          className={styled.maintenanceNotes}
          title={t('payers_maintenance_notes')}
        />
      );
    } else {
      return null;
    }
  }

  function renderContent() {
    const requestPayerNotes = isRequestable && (
      <Notes
        showIcon
        className={styled.requestPayer}
        title={parseHtml(
          t('request_payer', {
            href,
          }),
        )}
        icon={
          <Image
            src={`${staticFolder ?? ''}/images/svg/rocket.svg`}
            width={24}
            height={24}
            alt="rocket-icon"
          />
        }
      />
    );

    if (filteredPayers?.length === 0) {
      return (
        <Flex
          column
          align="center"
          justify="center"
          className={styled.emptyState}
        >
          <Flex align="center" justify="center" className="img">
            {emptyStateImage}
          </Flex>
          <Flex
            column
            align="center"
            justify="center"
            className="desc secondary-text"
          >
            <Text>{t('empty_search_result.title')}</Text>
            {requestPayerNotes}
          </Flex>
        </Flex>
      );
    }

    return (
      <Flex column className={styled.bankWrapper(showMaintenanceNotes)}>
        {filteredPayers?.map((payer) => {
          const { id, name, routingChannels, disabled } = payer || {};
          const isSelected = id === selectedPayerId;
          const supportedSpeed =
            routingChannel === RoutingChannelType.INSTANT
              ? t('normal_speed')
              : t('instant_speed');
          const description = (() =>
            !disabled ? (
              <Flex className={styled.pills}>
                {routingChannels?.map(({ type, isMaintenance }, idx) => {
                  const icon = isMaintenance ? (
                    <DelayFill width={16} height={16} />
                  ) : (
                    type === 'INSTANT' && <Thunder />
                  );
                  const message =
                    type === 'INSTANT' ? t('instant_speed') : t('normal_speed');
                  return (
                    <Status
                      key={idx}
                      icon={icon}
                      size="small"
                      color={isMaintenance ? 'yellow' : 'blue'}
                      message={message}
                    />
                  );
                })}
              </Flex>
            ) : (
              t('only_support', {
                supportedSpeed,
              })
            ))();
          return (
            <ListMenu
              key={id}
              hasDivider
              type="radio"
              title={name}
              isDisabled={disabled}
              isSelected={isSelected}
              description={description}
              className={`bank-name-item bank-name-item-${id} sm-text`}
              onClick={() => onSelectBank(id, routingChannels)}
            />
          );
        })}
        {requestPayerNotes}
      </Flex>
    );
  }

  useEffect(() => {
    const bankNameItem = document.querySelector(
      `.bank-name-item-${selectedPayerId}`,
    );

    if (bankNameItem) {
      bankNameItem?.scrollIntoView({ block: 'center' });
    }
  }, []);

  useDebounce(
    () => {
      const _filteredPayers = _payers?.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()),
      );
      setFilteredPayers(_filteredPayers);
    },
    [search],
    500,
  );

  return (
    <Flex column className={styled.root}>
      <Flex column className={styled.header}>
        <Text as="h5">{t('select_destination_bank')}</Text>
        <Searchbar
          value={search}
          className="searchbar"
          onChange={(value) => setSearch(value)}
          placeholder={searchPlaceholder || t('search_bank_name')}
        />
        {renderMaintenanceNotes()}
      </Flex>
      {renderContent()}
    </Flex>
  );
}

const styled = {
  root: css`
    h5 {
      margin-bottom: 1.5rem;
    }
  `,
  header: css`
    margin-bottom: 1rem;
  `,
  bankWrapper: (showMaintenanceNotes) => css`
    height: ${showMaintenanceNotes
      ? 'calc(75vh - 16rem - 62px)'
      : 'calc(75vh - 16rem)'};
    overflow-y: scroll;
    .bank-name-item {
      padding: 0.625rem 0;
      .content {
        .title {
          margin-bottom: 0.5rem;
        }
        .description {
          .bank-name {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  `,
  pills: css`
    .ant-tag {
      :not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  `,
  requestPayer: css`
    margin-top: 1rem;
  `,
  emptyState: css`
    min-height: calc(75vh - 268.68px);
    .img {
      min-height: 8.875rem;
    }
    .desc {
      margin-top: 0.5rem;
      text-align: center;
      color: var(--text-secondary);
    }
  `,
  maintenanceNotes: css`
    margin-top: 1rem;
  `,
};
