import { Recipient } from '@api-hooks/transaction';
import { requestFn } from '@common/client';
import { ApiResult, ApiError } from '@topremit/shared-web/api-hooks/api.model';
import { UseMutationOptions, useMutation } from 'react-query';

import {
  DeleteRecipientParams,
  EditAdditionalDetailsParams,
  EditRecipientDetailsParams,
  RecipientAdditionalDetailModel,
} from './recipient.model';

export function usePostRecipientDetails(
  refId: string,
  options?: UseMutationOptions<ApiResult<Recipient>, ApiError>,
) {
  return useMutation<ApiResult<Recipient>, ApiError, any>(
    async (params: Recipient) =>
      await requestFn(
        {
          path: `quotations/${refId}/recipients`,
          method: 'post',
        },
        { json: params },
      ),
    options,
  );
}

export function useEditRecipient(
  { id, refId }: { id: number; refId?: string },
  options?: UseMutationOptions<
    ApiResult<Recipient>,
    ApiError,
    EditRecipientDetailsParams
  >,
) {
  return useMutation<
    ApiResult<Recipient>,
    ApiError,
    EditRecipientDetailsParams
  >(
    async (params: EditRecipientDetailsParams) =>
      await requestFn(
        {
          path: `quotations/${params.referenceId || refId}/recipients/${id}`,
          method: 'put',
        },
        {
          json: params,
        },
      ),
    { retry: false, ...options },
  );
}

export function useValidateRecipientDetails(
  refId: string,
  options?: UseMutationOptions<ApiResult, ApiError, Recipient>,
) {
  return useMutation<ApiResult, ApiError, Recipient>(
    async (param: EditAdditionalDetailsParams) =>
      await requestFn(
        {
          path: `quotations/${refId}/recipients/validate`,
          method: 'post',
        },
        {
          json: { index: 'recipient-form', ...param },
        },
      ),
    { retry: false, ...options },
  );
}

export function useEditAdditionalDetails(
  { id, refId }: { id: number; refId: string },
  options?: UseMutationOptions<
    ApiResult<RecipientAdditionalDetailModel>,
    ApiError,
    EditAdditionalDetailsParams
  >,
) {
  return useMutation<
    ApiResult<RecipientAdditionalDetailModel>,
    ApiError,
    EditAdditionalDetailsParams
  >(
    async (param: EditAdditionalDetailsParams) =>
      await requestFn(
        {
          path: `quotations/${refId}/recipients/additional-info/${id}`,
          method: 'put',
        },
        {
          json: param,
        },
      ),
    { retry: false, ...options },
  );
}

export function useDeleteRecipient(
  options?: UseMutationOptions<ApiResult, ApiError, DeleteRecipientParams>,
) {
  return useMutation<ApiResult, ApiError, DeleteRecipientParams>(
    async (param: DeleteRecipientParams) =>
      await requestFn(
        {
          path: `recipients`,
          method: 'delete',
        },
        {
          json: param,
        },
      ),
    { retry: false, ...options },
  );
}
