import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { PayersModel } from '@topremit/shared-web/api-hooks/transaction';
import { UseQueryOptions } from 'react-query';

import {
  IGetPayersParams,
  IGetPurposeParams,
  IGetPurposeResponse,
} from './domestic.model';

export function useGetPayers(
  params: IGetPayersParams,
  options?: UseQueryOptions<ApiResult<PayersModel[]>, ApiError>,
) {
  const {
    url,
    serviceId,
    destinationCountry,
    destinationCurrency,
    transactionType,
  } = params;
  return useCustomQuery<PayersModel[]>(
    options?.queryKey || ['payers'],
    async () =>
      await requestFn(
        { path: `payers`, method: 'get' },
        {
          searchParams: {
            url,
            serviceId,
            destinationCountry,
            destinationCurrency,
            ...(transactionType && { transactionType }),
          },
        },
      ),
    {
      enabled:
        !!url &&
        !!serviceId &&
        !!destinationCountry &&
        !!destinationCurrency &&
        !!transactionType,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetPurposes(
  params: IGetPurposeParams,
  options?: UseQueryOptions<ApiResult<IGetPurposeResponse[]>, ApiError>,
) {
  const {
    url,
    serviceId,
    beneficiarySegment,
    destinationCountry,
    destinationCurrency,
  } = params;
  return useCustomQuery<IGetPurposeResponse[]>(
    ['purposes-domestic'],
    async () =>
      await requestFn(
        { path: `purposes`, method: 'get' },
        {
          searchParams: {
            url,
            serviceId,
            beneficiarySegment,
            destinationCountry,
            destinationCurrency,
          },
        },
      ),
    { ...options },
  );
}
