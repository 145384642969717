import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import { useCurrentAccountStore } from '@stores';
import {
  ApiError,
  ApiPaginationResult,
  ApiResult,
} from '@topremit/shared-web/api-hooks/api.model';
import { PayersModel } from '@topremit/shared-web/api-hooks/transaction/transaction.model';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from 'react-query';

import {
  AvailableRecipientsModel,
  GetAvailableRecipientFilterModel,
} from './recipient.model';

export function useGetAvailableRecipients(
  {
    limit = 5,
    filter,
    key,
  }: {
    limit?: number;
    filter: GetAvailableRecipientFilterModel;
    key: string | string[];
  },
  options?: UseInfiniteQueryOptions<
    ApiPaginationResult<AvailableRecipientsModel>,
    ApiError
  >,
) {
  const currentAccount = useCurrentAccountStore((s) => s.currentAccount);

  return useInfiniteQuery<
    ApiPaginationResult<AvailableRecipientsModel>,
    ApiError
  >(
    [
      'available-recipients',
      currentAccount?.id,
      filter.countryIsoCode,
      filter.currencyIsoCode,
      filter.serviceId,
      ...key,
    ],
    async ({ pageParam: page = 1 }) => {
      if (page !== false) {
        return await requestFn(
          {
            path: `v2/available-recipients`,
            method: 'get',
          },
          {
            searchParams: {
              page,
              limit,
              ...{ [`filter[search]`]: `${filter.searchRecipient || ''}` },
              ...{ [`filter[segment]`]: `${filter.segment}` },
              ...{
                [`filter[destination_country]`]: `${filter?.countryIsoCode}`,
              },
              ...{
                [`filter[destination_currency]`]: `${filter?.currencyIsoCode}`,
              },
              ...{ [`filter[service_id]`]: `${filter?.serviceId}` },
              ...(filter.transactionType && {
                transactionType: `${filter?.transactionType}`,
              }),
            },
          },
        );
      }
    },
    options,
  );
}

export function useGetLookupField(
  {
    serviceId,
    destinationCountry,
    destinationCurrency,
    routingChannel = '',
    bankBranchCode = '',
    refId = '',
  }: {
    serviceId: number;
    destinationCountry: string;
    destinationCurrency: string;
    routingChannel?: string;
    bankBranchCode?: string;
    refId?: string;
  },
  options?: UseQueryOptions<ApiResult<PayersModel>, ApiError>,
) {
  return useCustomQuery<PayersModel>(
    ['swiftCode', 'swiftCode'],
    async () =>
      await requestFn(
        {
          path: 'swift-code/check',
          method: 'get',
        },
        {
          searchParams: {
            serviceId,
            destinationCountry,
            destinationCurrency,
            bankBranchCode,
            routingChannel,
            refId,
          },
        },
      ),
    {
      ...options,
      enabled: false,
      retry: false,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetSwiftPayer(
  {
    serviceId,
    destinationCountry,
    destinationCurrency,
    queryKey,
  }: {
    serviceId: number;
    destinationCountry: string;
    destinationCurrency: string;
    queryKey: any;
  },
  options?: UseQueryOptions<ApiResult<PayersModel>, ApiError>,
) {
  return useCustomQuery<PayersModel>(
    [...queryKey],
    async () =>
      await requestFn(
        {
          path: 'swift-payer',
          method: 'get',
        },
        {
          searchParams: {
            serviceId,
            destinationCountry,
            destinationCurrency,
          },
        },
      ),
    {
      enabled: false,
      retry: false,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}
