import { css } from '@emotion/css';
import { ReactNode } from 'react';

import Status from '../Status';

export interface ISpeedDetail {
  fee: string;
  title: string;
  speed: string;
  arrivalTime: string;
  bottomElement?: ReactNode;
}

export default function SpeedInfo({
  fee,
  title,
  speed,
  arrivalTime,
  bottomElement,
}: ISpeedDetail) {
  return (
    <div className={styled.root}>
      <div className="xs-text title">{title}</div>
      <div className="speed-detail">
        <div>
          <div className="sm-bold-text">{speed}</div>
          <div className="xs-text time">{arrivalTime}</div>
        </div>
        <Status
          color="blue"
          message={
            <>
              {fee}
              {bottomElement}
            </>
          }
        />
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    .title {
      color: var(--text-secondary);
      margin-bottom: 0.5rem;
    }
    .speed-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .time {
        color: var(--text-secondary);
        margin-top: 0.25rem;
      }
    }
  `,
};
